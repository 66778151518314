<template>
  <div id="delivery-detail">
    <z-responsive-modal
      modal-id="delivery-detail-modal"
      :show.sync="showModal"
      size="xl"
      :hide-close="showParcelDetail"
      :body-class="
        `delivery-detail-modal-body ${showParcelDetail ? 'behind' : ''}`
      "
      @hide="handleClose"
    >
      <loading-spinner v-if="loading" class="mt-5" />
      <template v-else>
        <div class="d-flex">
          <service-type-avatar :type="type" size="lg" class="mr-3" />
          <div class="delivery-detail-container">
            <delivery-detail-header
              :is-parcel="isParcel"
              :delivery="delivery"
            />
          </div>
        </div>
        <div
          class="delivery-detail-cards d-md-inline-flex justify-content-between w-100"
        >
          <delivery-detail-card
            :type="type"
            :delivery="delivery"
            class="deliveries-flex"
          />
          <z-tabs
            pills
            class="delivery-detail-tabs deliveries-flex border rounded"
          >
            <z-tab lazy class="pb-3 " title="Mapa">
              <delivery-detail-map
                v-if="delivery"
                :origin="delivery.origin"
                :destination="delivery.destination"
              />
            </z-tab>
            <z-tab lazy class="pb-3" title="Registros">
              <delivery-detail-parcel-logs
                v-if="isParcel"
                :parcel-id="deliveryId"
              />
              <delivery-detail-logs v-else :delivery="delivery" :type="type" />
            </z-tab>
            <z-tab
              v-if="hasDeliveryProof"
              lazy
              class="pb-3"
              title="Evidencia de entrega"
            >
              <delivery-detail-proof :delivery-proof="delivery.deliveryProof" />
            </z-tab>
            <z-tab
              v-if="hasAdditionalOptions"
              lazy
              class="pb-3"
              title="Adicionales"
            >
              <delivery-detail-additionals
                :additionals="delivery.additionalOptions"
              />
            </z-tab>
            <z-tab v-if="isParcel" lazy class="pb-3" title="Guía">
              <delivery-detail-shipping-label :parcel-id="delivery.id" />
            </z-tab>
          </z-tabs>
        </div>
      </template>
    </z-responsive-modal>
  </div>
</template>

<script>
import DeliveryDetailHeader from "./DeliveryDetailHeader";
import DeliveryDetailCard from "./DeliveryDetailCard";
import DeliveryDetailLogs from "./DeliveryDetailLogs";
import DeliveryDetailMap from "./DeliveryDetailMap";
import DeliveryDetailParcelLogs from "./DeliveryDetailParcelLogs";
import DeliveryDetailProof from "./DeliveryDetailProof";
import DeliveryDetailAdditionals from "./DeliveryDetailAdditionals";
import DeliveryDetailShippingLabel from "./DeliveryDetailShippingLabel";
import ZResponsiveModal from "@zubut/common/src/components/ZResponsiveModal";
import ZTabs from "@zubut/common/src/components/ZTabs";
import ZTab from "@zubut/common/src/components/ZTab";
import LoadingSpinner from "@zubut/common/src/components/LoadingSpinner";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import Deliveries2 from "@/services/deliveries2";
import ServiceType from "@zubut/common/src/constants/services/type";
import Http from "@zubut/common/src/constants/http";

export default {
  name: "DeliveryDetail",

  components: {
    DeliveryDetailAdditionals,
    DeliveryDetailCard,
    DeliveryDetailHeader,
    DeliveryDetailLogs,
    DeliveryDetailMap,
    DeliveryDetailParcelLogs,
    DeliveryDetailProof,
    DeliveryDetailShippingLabel,
    LoadingSpinner,
    ServiceTypeAvatar,
    ZResponsiveModal,
    ZTabs,
    ZTab
  },

  data() {
    return {
      showModal: true,
      showParcelDetail: false,
      selectedParcel: "",
      delivery: null,
      loading: true
    };
  },

  computed: {
    deliveryId() {
      return this.$route.params.id;
    },

    type() {
      return this.delivery?.type ?? ServiceType.NUM_PARCEL_DELIVERY;
    },

    isParcel() {
      return ServiceType.isParcel(this.type);
    },

    hasAdditionalOptions() {
      const additionalOptions = this.delivery.additionalOptions || {};
      return additionalOptions.restrictedItems || additionalOptions.signature;
    },

    hasDeliveryProof() {
      return (
        this.delivery?.deliveryProof?.receiverName ||
        this.delivery?.deliveryProof?.receiverType ||
        this.delivery?.deliveryProof?.signature
      );
    }
  },

  created() {
    this.getService();
  },

  methods: {
    getService() {
      this.loading = true;
      Deliveries2.getDetails(this.deliveryId)
        .then(res => {
          this.delivery = res.data;
          this.loading = false;
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.$store.commit("setDisplayNotFound", true);
          } else {
            this.$captureError(err);
            this.loading = false;
          }
        });
    },
    handleClose() {
      this.$router.go(-1);
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss">
#delivery-detail,
#delivery-detail-modal {
  .delivery-detail-modal-body {
    min-height: 94vh;
    padding: 1.5rem 1.5rem 3rem 1.5rem !important;
    transition: background-color 300ms ease;
  }

  .tab-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .delivery-detail-container {
    width: 100%;
  }

  .delivery-detail-cards {
    margin-top: 32px;
    padding-left: 46px;
  }

  .delivery-detail-tabs {
    padding: 24px 24px 0 24px;
    margin-left: 24px;
  }

  @include media-breakpoint-down(lg) {
    .delivery-detail-tabs {
      margin-left: 0 !important;
      margin-top: 24px;
    }

    .delivery-detail-cards {
      padding-left: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .deliveries-flex {
      width: 50%;
    }
    .return-mobile {
      display: none;
    }
  }
}

@media print {
  .grid-page {
    display: none !important;
  }
}
</style>
